<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <!-- DateFrom -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="InvoiceSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- DateTo -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="InvoiceSearch.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>
        <!-- SeviceTypeID -->
        <div
          v-show="InvoiceSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Sevice Type") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="InvoiceSearch.SeviceTypeID"
            :options="SeviceType"
            :reduce="(ID) => ID.ID"
            @input="ChangeSearchFilter"
          />
        </div>
        <!-- PatientID -->
        <div
          v-show="InvoiceSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="InvoiceSearch.PatientID"
            :options="Patients"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- DoctorID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="InvoiceSearch.DoctorID"
            :options="doctors"
            :reduce="(ID) => ID.ID"
            :disabled="DoctorShow"
          />
        </div>
        <!-- HospitalID    -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{ $t("Hospitals") }} </label>
          <v-select
            required
            label="NameEN"
            class="w-full"
            v-model="InvoiceSearch.HospitalID"
            :options="Hospitals"
            :reduce="(ID) => ID.ID"
            :disabled="HospitalShow"
          />
        </div>
        <!-- StatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="InvoiceSearch.StatusID"
            :options="Status"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PaymentStatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="InvoiceSearch.PaymentStatusID"
            :options="PaymentStatuses"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="ProfitSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left: 10px"
              class="my-3"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
          <div class="float-right">
            <vue-excel-xlsx
              style="width: 12rem; height: 3rem; font-size: 1.5rem"
              :data="ProfitData"
              :columns="columns"
              :filename="'InvoiceReport'"
              :sheetname="'InvoiceReport'"
            >
              Export Exel
            </vue-excel-xlsx>
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      class="mt-12"
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="ProfitData"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                ProfitData.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : ProfitData.length
              }}
              of {{ ProfitData.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Inovice No") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Booking Reference ") }}</vs-th>

        <vs-th sort-key="Day">{{ $t("Sevice Date ") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Sevice Type ") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Doctor/Hospital") }}</vs-th>
        <vs-th>{{ $t("Patient's Country") }}</vs-th>
        <vs-th>{{ $t("Doctor/Hospital's Country") }}</vs-th>

        <vs-th sort-key="Day">{{ $t("Selling Price") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Currency") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Invoiced Amount") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Currency") }}</vs-th>

        <vs-th sort-key="Doctor">{{ $t("Booking Status") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Paymetent Status") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Generated Invoice") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">
                INV-{{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.Date }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ServiceName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PatientName }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DoctorName" class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorName }}
              </p>
              <p
                v-if="tr.HospitalName"
                class="product-name font-medium truncate"
              >
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.HospitalName }}
              </p>
            </vs-td>
         <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PatientCountry }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DoctorHospitalCountry" class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorHospitalCountry }}
              </p>

            </vs-td>


            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.SellingPrice }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.NetPriceCurrencyName }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.SellingPrice }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.NetPriceCurrencyName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.StatusName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PaymentStatusName }}
              </p>
            </vs-td>
            <vs-td>
              <feather-icon
                icon="ExternalLinkIcon"
                class="mb-4"
                svgClasses="h-8"
                style="cursor: pointer"
                @click="GenerateInvoice(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- generate pdf invoice  -->
    <div dir="ltr">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1500"
        filename="Invoice"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="Invoice2Pdf"
      >
        <section slot="pdf-content" class="m-12" style="width: 90%">
          <div class="text-center w-full h-full" style="min-height: 700px">
            <!-- header -->
            <div class="vx-row">
              <!-- names -->
              <div class="vx-col lg:w-1/2">
                <table>
                  <tr class="desSendTable">
                    <td class="pt-3">
                      <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                        {{ $t("DocliniaComName") }}
                      </h6>
                    </td>
                  </tr>
                  <tr class="desSendTable">
                    <td>
                      <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                        {{ $t("DocliniaComAddress") }}
                      </h6>
                    </td>
                  </tr>
                  <tr class="desSendTable">
                    <td>
                      <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                        {{ $t("DocliniaComCity") }}
                      </h6>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- img -->
              <div class="vx-col lg:w-1/2">
                <div style="text-align: right">
                  <img
                    src="@/assets/images/logo/docliniaLogo.png"
                    style="width: 23rem; height: 7rem"
                    class="mx-auto m-3"
                  />
                </div>
              </div>
            </div>

            <!-- title section -->
            <div class="flex flex-wrap justify-center mt-3">
              <div
                class="vx-row mt-8 pt-4 fontheader"
                style="background: #016a86; height: 5rem; width: 95%"
              >
                <!-- Date -->
                <div class="vx-col lg:w-1/2">
                  <div style="text-align: left">
                    <h2 style="color: white">
                      <label v-if="flag == 1">
                        {{ changeFormatedDate(PatientReservationSession.Date) }}
                      </label>
                      <label v-if="flag == 3">
                        <label
                          style="color: #004477"
                          v-if="PatientReservationPackage.Date"
                        >
                          {{
                            changeFormatedDate(PatientReservationPackage.Date)
                          }}
                        </label>
                      </label>
                      <label v-else>
                        <label
                          style="color: #004477"
                          v-if="PatientReservationSurgery.Date"
                        >
                          {{
                            changeFormatedDate(PatientReservationSurgery.Date)
                          }}
                        </label>
                      </label>
                    </h2>
                  </div>
                </div>

                <!-- RefNumber -->
                <div class="vx-col lg:w-1/2">
                  <h2 style="color: white">
                    {{ $t("InvoiceTitleReport") }} :
                    <labal v-if="flag == 1"
                      >INV-{{
                        PatientReservationSession.ReferenceNumber
                      }}</labal
                    >
                    <labal v-if="flag == 2"
                      >INV-{{
                        PatientReservationSurgery.ReferenceNumber
                      }}</labal
                    >
                    <labal v-if="flag == 3"
                      >INV-{{
                        PatientReservationPackage.ReferenceNumber
                      }}</labal
                    >
                  </h2>
                </div>
              </div>
            </div>

            <!-- body section -->
            <div class="flex flex-wrap justify-center mt-6">
              <div
                class="text-center h-full mt-3 pl-4 pr-4"
                style="min-height: 700px; width: 95%"
              >
                <!-- first section -->
                <div class="vx-row mt-3">
                  <table style="width: 40%">
                    <!-- INVOICETO -->
                    <tr class="fontheader" style="height: 2.5rem">
                      <th>
                        <h1 class="mb-3">
                          {{ $t("INVOICETO") }}
                        </h1>
                        <hr class="line mb-2" style="width: 80%" />
                      </th>
                    </tr>
                    <!-- PatientName -->
                    <tr class="desSendTable">
                      <td class="">
                        <!-- name -->
                        <h4 style="color: black" class="">
                          <label style="color: #004477" v-if="flag == 1">
                            {{ PatientReservationSession.PatientName }}</label
                          >
                          <label style="color: #004477" v-if="flag == 2">
                            {{ PatientReservationSurgery.PatientName }}</label
                          >
                          <label style="color: #004477" v-if="flag == 3">
                            {{ PatientReservationPackage.PatientName }}</label
                          >
                          <!-- this.patientObj.Name -->
                        </h4>
                      </td>
                    </tr>
                    <!-- Address -->
                    <tr class="desSendTable">
                      <td class="pl-3 pr-3">
                        <h4 style="color: black">
                          <label style="color: #004477">
                            <!-- {{ this.patientObj.Address }} -->
                          </label>
                        </h4>
                      </td>
                    </tr>
                    <!-- CountryName -->
                    <tr class="desSendTable">
                      <td class="pl-3 pr-3">
                        <h4 style="color: black">
                          <label style="color: #004477">
                            <!-- {{ this.patientObj.CountryName }} -->
                          </label>
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>

                <!-- secound section -->
                <div class="vx-row mt-12">
                  <table
                    class="mt-12"
                    style="width: 100%; background-color: white  border: 1px solid black; "
                  >
                    <tr
                      class="mb-12 pb-12"
                      style="
                        background: #016a86;
                        height: 5rem;
                        width: 100%;
                        color: white;
                      "
                    >
                      <th class="text-center">
                        <h1 style="color: white">
                          {{ $t("SERVICE") }}
                        </h1>
                      </th>
                      <th class="text-center">
                        <h1 style="color: white">
                          {{ $t("DESCRIBTION") }}
                        </h1>
                      </th>
                      <th class="text-center">
                        <h1 style="color: white">
                          {{ $t("TOTAL") }}
                        </h1>
                      </th>
                    </tr>
                    <tr class="desSendTable pt-12 text-center">
                      <td class="pl-3 pr-3">
                        <h4 v-if="flag == 1" style="color: black">
                          {{ $t("OnlineConsultation") }}
                        </h4>

                        <h4 v-if="flag == 2" style="color: black">
                          {{ $t("Surgery") }}
                        </h4>

                        <h4 v-if="flag == 3" style="color: black">
                          {{ $t("Package") }}
                        </h4>
                      </td>

                      <td class="pl-3 pr-3">
                        <h4 style="color: black" v-if="flag == 1">
                          {{ $t("DoctorNameRep") }}:<label
                            style="color: #004477"
                          >
                            {{ PatientReservationSession.DoctorName }}
                          </label>
                        </h4>
                        <h4 style="color: black" v-else>
                          {{ $t("HospitalNameRep") }}:<label
                            style="color: #004477"
                          >
                            {{ PatientReservationSurgery.HospitalName }}
                          </label>
                        </h4>
                      </td>
                      <td class="pl-3 pr-3" v-if="flag == 1">
                        <h4 style="color: black">
                          {{ paymentObj.price - PatientReservationSession.VAT }}
                          {{ $t("USD") }}
                        </h4>
                      </td>
                      <td class="pl-3 pr-3" v-if="flag == 2">
                        <h4 style="color: black">
                          {{ paymentObj.price - PatientReservationSurgery.VAT }}
                          {{ $t("USD") }}
                        </h4>
                      </td>
                      <td class="pl-3 pr-3" v-if="flag == 3">
                        <h4 style="color: black">
                          {{ paymentObj.price - PatientReservationPackage.VAT }}
                          {{ $t("USD") }}
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
                <hr class="line mb-2 mt-8" style="width: 100%" />

                <!-- third section -->
                <div class="vx-row mt-5 w-full">
                  <div class="vx-col lg:w-3/5"></div>
                  <div class="vx-col lg:w-2/5">
                    <table>
                      <!-- subTotal -->
                      <tr class="desSendTable">
                        <td class="pl-5 pr-5 pt-3">
                          <h4 style="color: black">
                            {{ $t("SUBTOTAL") }}:<label
                              style="color: #004477"
                              v-if="this.flag == 1"
                            >
                              {{
                                paymentObj.price - PatientReservationSession.VAT
                              }}

                              {{ $t("USD") }}</label
                            >
                            <label style="color: #004477" v-if="this.flag == 2">
                              {{
                                paymentObj.price - PatientReservationSurgery.VAT
                              }}

                              {{ $t("USD") }}</label
                            >
                            <label style="color: #004477" v-if="this.flag == 3">
                              {{
                                paymentObj.price - PatientReservationPackage.VAT
                              }}

                              {{ $t("USD") }}</label
                            >
                          </h4>
                        </td>
                      </tr>
                      <!-- vat -->
                      <tr class="desSendTable">
                        <td class="pl-5 pr-5">
                          <h4 style="color: black">
                            {{ $t("VAT") }}:<label
                              style="color: #004477"
                              v-if="this.flag == 1"
                            >
                              {{ PatientReservationSession.VAT }}
                              USD</label
                            >
                            <label style="color: #004477" v-if="this.flag == 2">
                              {{ PatientReservationSession.VAT }}
                              USD</label
                            >
                            <label style="color: #004477" v-if="this.flag == 3">
                              {{ PatientReservationPackage.VAT }}
                              USD</label
                            >
                          </h4>
                        </td>
                      </tr>
                      <!-- total -->
                      <tr class="desSendTable">
                        <td class="pl-5 pr-5">
                          <h4 style="color: black">
                            {{ $t("TOTAL") }} :<label style="color: #004477">
                              {{ paymentObj.price }}
                              {{ $t("USD") }}</label
                            >
                          </h4>
                        </td>
                      </tr>
                      <!-- date -->
                      <tr class="desSendTable">
                        <td class="pl-5 pr-5">
                          <h6 style="color: black">
                            {{ $t("DATE") }} :<label
                              style="color: #004477"
                              v-if="flag == 1"
                            >
                              {{
                                changeFormatedDate(
                                  PatientReservationSession.Date
                                )
                              }}
                            </label>
                            <label style="color: #004477" v-if="flag == 2">
                              <label
                                style="color: #004477"
                                v-if="PatientReservationSurgery.Date"
                              >
                                {{
                                  changeFormatedDate(
                                    PatientReservationSurgery.Date
                                  )
                                }}
                              </label>
                            </label>
                            <label style="color: #004477" v-if="flag == 3">
                              {{
                                changeFormatedDate(
                                  PatientReservationPackage.Date
                                )
                              }}
                            </label>
                          </h6>
                        </td>
                      </tr>
                      <tr class="desSendTable">
                        <td class="pl-5 pr-5">
                          <h6 style="color: black">
                            {{ $t("FooterRep") }}
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Datepicker from "vuejs-datepicker";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import VueExcelXlsx from "vue-excel-xlsx";
import moment from "moment";
import Vue from "vue";
Vue.use(VueExcelXlsx);
export default {
  components: {
    Datepicker,
    VueHtml2pdf,
  },
  data() {
    return {
      selected: [],
      flag: 0,
      PatientReservationSurgery: {},
      PatientReservationSession: {},
      PatientReservationPackage: {},
      paymentObj: {},
      search: {},
      columns: [
        {
          label: "Booking Reference ",
          field: "ReferenceNumber",
        },

        {
          label: "SeviceDate",
          field: "Date",
        },
        {
          label: "Sevice Type",
          field: "ServiceName",
        },
        {
          label: "Patient",
          field: "PatientName",
        },
        {
          label: "Doctor",
          field: "DoctorName",
        },

        {
          label: "Hospital",
          field: "HospitalName",
        },

        {
          label: "Selling Price",
          field: "SellingPrice",
        },

        {
          label: "Currency",
          field: "NetPriceCurrencyName",
        },
        {
          label: "Invoiced Amount",
          field: "SellingPrice",
        },

        {
          label: "Currency",
          field: "NetPriceCurrencyName",
        },

        {
          label: "Booking Status",
          field: "StatusName",
        },
        {
          label: "Patment Status",
          field: "PaymentStatusName",
        },
      ],
      // Patients: [],
      Status: [],
      PaymentStatuses: [],
      itemsPerPage: 10,
      Invoice: {},
      isMounted: false,
      HospitalShow: false,
      DoctorShow: false,
      rowDataForDelete: {},
//       InvoiceSearch:{
//         DoctorID:null,
// HospitalID:null,
// StatusID:null,
// PaymentStatusID:null,
// SeviceTypeID:null,
// PatientID:null,
//       },

      SeviceType: [
        { ID: 1, Name: "Online Appointment" },
        { ID: 2, Name: "Surgery" },
        { ID: 3, Name: "Medical Package" },
      ],
      activeConfirm: false,
    };
  },
  computed: {
    InvoiceSearch() {
      debugger;
      return this.$store.state.ReservationList.InvoiceSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    ProfitData() {
      debugger;
      return this.$store.state.patientList.ProfitData;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      return this.$store.state.patientList.patients;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
  },

  methods: {
    changeFormatedDate(date) {
      debugger;
      return moment(date).format("LL");
    },

    GenerateInvoice(tr) {
      debugger;
      if (tr.ServiceType == 1) {
        this.flag = 1;
        debugger;
        this.PatientReservationSession = tr;
        this.paymentObj.price = tr.SellingPrice;
        this.paymentObj.CurrencyName = tr.NetPriceCurrencyName;
        this.$refs.Invoice2Pdf.generatePdf();
        debugger;
      }
      if (tr.ServiceType == 2) {
        this.flag = 2;
        this.PatientReservationSurgery = tr;
        this.paymentObj.price = tr.SellingPrice;
        this.paymentObj.CurrencyName = tr.NetPriceCurrencyName;
        debugger;
        this.$refs.Invoice2Pdf.generatePdf();
      }
      if (tr.ServiceType == 3) {
        this.flag = 3;
        this.PatientReservationPackage = tr;
        this.paymentObj.price = tr.SellingPrice;
        this.paymentObj.CurrencyName = tr.NetPriceCurrencyName;
        debugger;
        this.$refs.Invoice2Pdf.generatePdf();
      }
    },
    resetData() {
    this.$store.state.ReservationList.InvoiceSearchObj= {
      DateFrom: null,
    DateTo: null,
    SeviceTypeID: null,
    PatientID: null,
    DoctorID: null,
    HospitalID: null,
    StatusID: null,
    PaymentStatusID: null,
    IsPrivate:null,
    };
    this.$store.state.ReservationList.InvoiceSearch= {};

      this.$store.state.ReservationList.InvoiceSearchObj.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.$store.state.ReservationList.InvoiceSearchObj.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
        this.InvoiceSearch.DateFrom= this.$store.state.ReservationList.InvoiceSearchObj.DateFrom ;
        this.InvoiceSearch.DateTo= this.$store.state.ReservationList.InvoiceSearchObj.DateTo ;
        this.InvoiceSearch.SeviceTypeID=null;
        this.InvoiceSearch.PaymentStatusID=null;
        this.InvoiceSearch.HospitalID=null;
        this.InvoiceSearch.DoctorID=null;
        this.InvoiceSearch.StatusID=1;
        this.InvoiceSearch.PatientID=null;

        this.ProfitSearch();
    },

    ProfitSearch() {
      debugger;
      this.$store.dispatch("patientList/GetProfitDataReport", this.InvoiceSearch);
    },

    GetPatient() {
      this.$store
        .dispatch("patientList/SearchPatients", {})
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    GetAllStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllStatuses")
        .then((res) => {
          this.Status = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    GetAllPaymentStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllPaymentStatuses")
        .then((res) => {
          this.PaymentStatuses = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ChangeSearchFilter() {
      debugger;
      if (this.InvoiceSearch.SeviceTypeID == 2 || this.SeviceTypeID == 3) {
        this.DoctorShow = true;
        this.HospitalShow = false;
      }

      if (this.InvoiceSearch.SeviceTypeID == 1) {
        this.HospitalShow = true;
        this.DoctorShow = false;
      }
      if (
        this.InvoiceSearch.SeviceTypeID == 0 ||
        this.InvoiceSearch.SeviceTypeID == null ||
        this.InvoiceSearch.SeviceTypeID == undefined
      ) {
        this.DoctorShow = false;
        this.HospitalShow = false;
      }
    },
  },

  created() {
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.GetPatient();
    debugger;
    this.GetAllStatuses();
    this.GetAllPaymentStatuses();


    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    this.$store.dispatch("HospitalList/GetAllHospitals", {});
      this.resetData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.desSendTable {
  height: 2.5rem;
  text-align: left;
}
</style>
